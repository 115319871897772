import React, { useState, useEffect } from "react";
import customFetch from "../utils/fetch";
import { API_URL } from "../utils/constants";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment, { Moment } from "moment";
import VendClientSelection from "../EmergencyCheckout/vendClientSelection";
import { getCustomers } from "../EmergencyCheckout/vend";

export default function Payments() {
  const [payments, setPayments] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[] | null>();
  const [customerId, setCustomerId] = useState('') as any;
  const [seed, setSeed] = useState(1);
  const [customer, setCustomer] = useState() as any;
  const [customerError, setCustomerError] = useState(false);
  const [showFailureReason, setShowFailureReason] = useState("");
  const [focused, setFocused] = useState<FocusedInputShape>(null);
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().add({ day: -1 })
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    moment().add({ day: -1 })
  );

  useEffect(() => {
    if (!customers) {
      getCustomers().then((custs) => {
        setCustomers(custs?.customers);
      });
      //setCustomers([]);
    }


    if (customerId) {
      customFetch(`${API_URL}/subscriptions/vend/${customerId}`).then((subscriptions) => {
        let paymentArr = [];

        subscriptions.map((subscription: any) => {
          subscription.payments.map((payment: any) => {
            paymentArr.push({
              'paymentDate': payment.paymentDate,
              'status': payment.status,
              'amount': payment.amount,
              'id': payment.id,
              'failureReason': payment.failureReason,
              'subscription': {
                'service': subscription.service,
                'vendUser': {
                  'vendUserId': subscription.vendUser.vendUserId
                }
              },
            });
          });
        });
        setPayments(paymentArr);
      });
    } else if (startDate && endDate) {
      customFetch(`${API_URL}/subscriptions/payments/${startDate.format(
        "M-D-YYYY"
      )}/${endDate.format("M-D-YYYY")}/${customerId}`).then(({ payments }) => {
        setPayments(payments);
      });
    }

  }, [customers, startDate, endDate, customerId]);



  /*
  {
  "id": "ck9ekhyb60001yurvmivp3e18",
  "amount": 0.01,
  "paymentDate": "2020-04-24T04:00:00.000Z",
  "status": "APPROVED",
  "failureReason": null,
  "updatedAt": "2020-04-24T19:08:00.606Z",
  "createdAt": "2020-04-24T19:07:59.346Z",
  "subscriptionId": "ck9ekhya10000yurvy9dvmvf9",
  "subscription": {
    "id": "ck9ekhya10000yurvy9dvmvf9",
    "occurence": "MONTHLY",
    "amount": 0.01,
    "vendUserId": "ck9dthggz0007oarvkgdkhdho",
    "service": "TP - Triple Plan - PA/ NP",
    "updatedAt": "2020-04-24T19:07:59.305Z",
    "createdAt": "2020-04-24T19:07:59.305Z",
    "vendUser": {
      "id": "ck9dthggz0007oarvkgdkhdho",
      "vendUserId": "069db350-8d3f-11ea-f6a9-790aa75f9638",
      "vendUrl": "formulawellnesscenter",
      "tokenizedCardId": "ck9jbq06200025drvczsqb84i",
      "projectId": "ck9dtgb9x0001oarv0twvzwg8",
      "updatedAt": "2020-04-24T06:31:46.595Z",
      "createdAt": "2020-04-24T06:31:46.595Z"
    }
  }
}
  */

  function LineItem({
    label,
    children,
    className,
  }: {
    label: string;
    children?: any;
    className?: string;
  }) {
    return (
      <div className={`${className || "w-1/6"} mx-2`}>
        <div className="text-sm text-gray-600">{label}</div>
        {children}
      </div>
    );
  }

  return customers ? (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">

      <div className="flex flex-col lg:w-full md:flex-row bg-white ">
        <div className="w-1/2 p-6 mb-4 ">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Range
          </label>
          <DateRangePicker
            startDate={startDate}
            startDateId="startDate"
            endDate={endDate}
            endDateId="endDate"
            isOutsideRange={() => false}
            regular={true}
            withPortal={true}
            minimumNights={0}
            focusedInput={focused}
            onFocusChange={(f) => setFocused(f)}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        </div>

        <div style={{paddingTop:'52px'}}>
          OR
        </div>
        <div className="p-5 w-1/2">
          <button className="float-right" onClick={() => {
            setCustomer(false);
            setCustomerId('');
            setSeed(Math.random());
          }}>❌</button>
          <VendClientSelection
            key={seed}
            customers={customers}
            onCustomerSelect={(vendCustomer) => {
              setCustomerError(false);
              setCustomer(vendCustomer);
              setCustomerId(vendCustomer.id);
            }}
          />
          {customerError && (
            <div className="text-sm text-red-600">
              Please select a vend customer
            </div>
          )}
        </div>
      </div>

      <div className="bg-white w-full p-6 flex flex-col">
        <div className="flex flex-col">
          {payments?.map((payment: any) => {
            const customer = customers?.find(
              (cust: any) =>
                cust.id === payment?.subscription?.vendUser?.vendUserId
            );
            const paymentStatusColor =
              payment.status === "FAILED"
                ? "text-red-500"
                : payment.status === "OPEN"
                  ? "text-yellow-600"
                  : "text-green-500";
            return (
              <div className="p-4 bg-gray-300 m-4 ">
                <div className="flex justify-start">
                  <LineItem label="Payment Date" className="w-1/4">
                    {new Date(payment.paymentDate).toLocaleString()}
                  </LineItem>
                  <LineItem label="Status">
                    {<div className={paymentStatusColor}>{payment.status}</div>}
                  </LineItem>

                  <LineItem label="Amount">
                    {<div>{payment.amount}</div>}
                  </LineItem>
                  <LineItem label="Name">
                    {customer?.first_name} {customer?.last_name}
                  </LineItem>
                  <LineItem label="Service" className="w-1/4">
                    {payment?.subscription?.service}
                  </LineItem>
                </div>
                <div className="text-sm text-gray-500">{payment.id}</div>
                {payment.status === "FAILED" && (
                  <div className="flex flex-col">
                    <div
                      className="text-sm text-gray-500"
                      onClick={() =>
                        showFailureReason === ""
                          ? setShowFailureReason(payment.id)
                          : setShowFailureReason("")
                      }
                    >
                      Show Worldpay Response
                    </div>
                    {showFailureReason === payment.id && (
                      <pre className="text-sm text-gray-700">
                        {JSON.stringify(
                          JSON.parse(payment.failureReason),
                          null,
                          2
                        )}
                      </pre>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
