import React, { useState, FormEvent } from "react";
// import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../utils/constants";
import { webTokenKey } from "../utils/fetch";
import history from "../utils/history";

export default function Login(props: any) {
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  function handleLogin(e: FormEvent) {
    e.preventDefault();
    fetch(`${BASE_API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(async (resp) => {
      if (resp.status === 400) {
        return setError(true);
      }
      const json = await resp.json();
      localStorage.setItem(webTokenKey, json.jwtToken);
      setTimeout(() => {
        const state: any = history.location.state;
        const locationToNav = state?.from?.pathname || "/uptown/subscription";
        // navigate(locationToNav);
        window.location.href = "https://staging.formulawellness.cc" + locationToNav;
      }, 300);
    });
  }

  return (
    <div className="w-full max-w-xs m-auto mt-16">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={handleLogin}
      >
        {error && (
          <p className="text-red-500 text-xs italic border border-red-500 p-3 mb-4">
            Invalid email or password
          </p>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="person@place.com"
            value={email}
            onChange={(e) => {
              setError(false);
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            value={password}
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
        </div>
      </form>
      <p className="text-center text-gray-500 text-xs">
        &copy;{new Date().getFullYear()} Radial Pay. All rights reserved.
      </p>
    </div>
  );
}
